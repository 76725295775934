import { CircularProgress, Typography } from '@material-ui/core'
import React from 'react'


export default props => {
    return(
        <div className="spinner-container">
            <CircularProgress color="primary" />
            { props.text ? <Typography>{props.text}</Typography> :  null}
        </div>
    )
}