import A from './actionTypes';
const localStorageKey = 'SESOrgUI';

let localStorageState;

try {
  localStorageState = localStorage.getItem(localStorageKey)
    ? JSON.parse(localStorage.getItem(localStorageKey))
    : {};
} catch (e) {
  localStorageState = {};
}

const initialState = {
  ...{    
    messages: [],
    showModal: false,
    showDrawer: true,
    modalContents: {},
    message: null, // {message: String, type: String (danger|warning|success|info)}
  },
  // overwrite values from local state, if any
  ...localStorageState,
};

export default function root(state = initialState, action) {
  let newState = { ...state };
  let { messages } = state;
  const { type, payload} = action;

  switch ( type) {
    case A.SHOW_MESSAGE:
      if (Array.isArray(messages)) {
        // todo: validate payload type
        messages = [...messages, payload];
        newState = { ...state, messages };
      }
      break;
    case A.DELETE_MESSAGE:
      if (Array.isArray(messages) && messages.length > 0) {
        messages = [...messages.splice(0, messages.length - 1)];
        newState = { ...state, messages };
      }
      break;
    case A.SHOW_MODAL:
      newState = { ...state, showModal: true, modalContents: payload };
      break;
    case A.CLOSE_MODAL:
      newState = { ...state, showModal: false, modalContents: {} };
      break;

    case A.TOGGLE_DRAWER:
        newState = { ...state, showDrawer: !state.showDrawer };
        break;
  
    
    default:
    //newState = state;
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newState));

  return newState;
}
