import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ProgressService from '../../../../../services/progress';
import { buildLink } from '../../../../../utils/functions';
import './preface.scss';

class Preface extends React.Component {
  state = {
    btnText: 'Get started',
  };

  componentDidMount() {
    this.fetchProgress();
  }

  async fetchProgress() {
    const result = await ProgressService.getCourseProgress(
      this.props.course.course_id
    );
    console.log('Preface - result: ', result);

    if (result.status === 'open' && parseInt(result.progress_percent) >= 0) {
      this.setState({ btnText: 'Continue learning' });
    }
  }

  markup = (html) => {
    return { __html: html };
  };

  render() {
    const {html} = typeof this.props.course === "object" ? this.props.course : {};
    
    const {
      placeholder_text = 'Welcome to this course.',
      organization,
      prog_id: program,
      course = {},
    } = this.props;

    const courseLink = buildLink({
      organization,
      program,
      course: course.course_id,
      slug: 'experiment',
    });

    return (
      <div className='course-preface'>
        {html ? (
          <div dangerouslySetInnerHTML={this.markup(html)}></div>
        ) : (
          <div className='placeholder-text'>{placeholder_text}</div>
        )}
        <div className='button-wrapper'>
          <Button
            component={Link}
            to={courseLink}
            variant='contained'
            color='primary'
          >
            {this.state.btnText}
          </Button>
        </div>
      </div>
    );
  }
}

export default Preface;
