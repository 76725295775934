import React from 'react';
import {
  Modal,
  Grid,
  Typography,
  Container,
  Button,
  TextField,
} from '@material-ui/core';
import Service from '../../services/AuthService';
import { connect } from 'react-redux';
import { showMessage } from '../../redux/notificationActions';
import Spinner from '../../components/template/spinner';
import { Link } from 'react-router-dom';
import './styles.scss';

class UserProfile extends React.Component {
  state = {
    password: '',
    password2: '',
    currentUser: this.props.reduxUser,
    editPasswordModalOpen: false,
  };

  componentDidMount() {
    console.log('USER: ', this.state.currentUser);
  }

  async changePasswordHandler() {
    const { currentUser } = this.state;
    // TODO:
    // 1. Send password change to server
    const result = await Service.changePassword(currentUser.id);
    if (!result) {
      this.props.onShowMessage('Password update failed', 'error');
    }
    this.closeEditPasswordModal();
  }

  closeEditPasswordModal() {
    this.setState({
      editPasswordModalOpen: false,
      password: '',
      password2: '',
    });
  }

  render() {
    const { currentUser, editPasswordModalOpen, password, password2 } =
      this.state;

    return (
      <Container className='user-profile'>
        {currentUser ? (
          <>
            <Grid container xs={6} className='details-container'>
              <Grid item xs={4}>
                <Typography className='details-text'>User ID:</Typography>
                <Typography className='details-text'>First Name:</Typography>
                <Typography className='details-text'>Last Name:</Typography>
                <Typography className='details-text'>Email:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className='details-text'>
                  {currentUser.id}
                </Typography>
                <Typography className='details-text'>
                  {currentUser.name}
                </Typography>
                <Typography className='details-text'>
                  {currentUser.last_name}
                </Typography>
                <Typography className='details-text'>
                  {currentUser.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={5} className='image-container'>
              <img
                src={`${process.env.PUBLIC_URL}/images/userPlaceholder.jpg`}
                alt='user-placeholder'
                className='image'
              ></img>
            </Grid>
            <Grid container xs={12} className='buttons-container'>
              <Button
                variant='contained'
                color='primary'
                component={Link}
                to={'/organizations'}
              >
                Back
              </Button>
              <Button
                variant='contained'
                onClick={() =>
                  this.setState({
                    editPasswordModalOpen: true,
                  })
                }
                color='secondary'
                style={{ margin: '15px' }}
              >
                Change Password
              </Button>
            </Grid>
          </>
        ) : (
          <Spinner />
        )}
        {editPasswordModalOpen ? (
          <Modal
            open={editPasswordModalOpen}
            onClose={() => this.closeEditPasswordModal()}
          >
            <Grid container className='password-modal-container'>
              <Grid item className='header-container'>
                <Typography className='header'>
                  Please choose new password
                </Typography>
              </Grid>
              <Grid item className='fields'>
                <TextField
                  id='password1'
                  label='Password'
                  variant='outlined'
                  type='password'
                  style={{ marginBottom: '6px' }}
                  onChange={(event) =>
                    this.setState({ password: event.target.value })
                  }
                  value={password}
                />
                <TextField
                  id='password2'
                  label='Confirm Password'
                  variant='outlined'
                  type='password'
                  onChange={(event) =>
                    this.setState({ password2: event.target.value })
                  }
                  value={password2}
                />
              </Grid>
              <Grid item className='buttons-container'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => this.closeEditPasswordModal()}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  style={{ marginLeft: '15px', marginRight: '10px ' }}
                  onClick={() => this.changePasswordHandler()}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Modal>
        ) : null}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

const stateToProps = (state) => ({ reduxUser: state.root.user });

export default connect(stateToProps, mapDispatchToProps)(UserProfile);
