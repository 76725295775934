import React, { useState, useEffect } from "react";
import {
  TextField,
  Input,
  InputAdornment,
  Button,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import Page404 from "../template/Page404";
import { ChevronLeft, Email, Home } from "@material-ui/icons";
import "./login.scss";
import { Link } from "react-router-dom";
import WithTranslations from "../WithTranslations";
import Spinner from "../template/spinner";
import AuthService from "../../services/AuthService";
import AutoBreadcrumbs from "../../shared-components/template/AutoBreadcrumbs";
import { showMessage } from "../../redux/notificationActions";

import {connect} from 'react-redux'

let breadcrumbs = [
  { to: "/", icon: <Home /> },
  { text: "Reset password" },
];

const ResetPassword2 = ({ hash }) => {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ name: "", last_name: "" });
  const [resolve, setResolve] = useState({ status: "", message: "" });

  useEffect(() => {
    // start off by showing loading process
    setLoading(true);
    let checkResult = null;
    console.log("HASH: ", hash);
    // checks hash for validity and populates state with result
    async function probeHash() {
      checkResult = await AuthService.checkHash(hash);

      if (checkResult) {
        setLoading(false);
        setData({ name: checkResult.name, last_name: checkResult.last_name });
      } else {
        setError("404");
      }
    }

    probeHash();
  }, []);

  const validatePasswords = () => {
    setError(null);
    if (password.length < 6 || password2.length < 6) {
      setError("Password must be at least 6 charachters long");
    }
    if (password !== password2) {
      setError("Passwords must match");
    }
  };

  const submitPasswords = async () => {
    if (password === "" || password2 === "") {
      setError("Password required");
      return;
    }

    const result = await AuthService.updatePasswordAfterReset(password, hash);
    if (!result) {
      setResolve({
        status: "fail",
        message: "Password could not be reset, please try again",
      });
    } else {
      setResolve({
        status: "success",
        message: "Password was reset, you can now login",
      });
    }
  };

  return error === "404" ? (
    <Page404 breacrumbsItems={breadcrumbs} />
  ) : !loading && data ? (
    <div id="reset-password-first">
      <div className="reset-form">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary">
              {resolve.status !== ""
                ? resolve.message
                : `Hello ${data.name} ${data.last_name}, please enter your new password`}
            </Typography>
          </Grid>

          {resolve.status !== "" ? null : (
            <>
              <Grid item xs={12}>
                <TextField
                  id="password1"
                  label="Password"
                  type="password"
                  error={error ? true : false}
                  helperText={error}
                  onBlur={validatePasswords}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="password2"
                  label="Confirm Password"
                  type="password"
                  error={error ? true : false}
                  helperText={error}
                  onBlur={validatePasswords}
                  onMouseLeave={() => console.log("MOUSE LEFT")}
                  onChange={(event) => setPassword2(event.target.value)}
                />
              </Grid>
            </>
          )}

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {resolve.status !== "" ? (
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "15px" }}
                component={Link}
                to={resolve.status === "success" ? "/" : "/reset-password"}
              >
                {resolve.status === "success"
                  ? "Go to login page"
                  : "Go to reset page"}
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "15px" }}
                  component={Link}
                  to={"/"}
                >
                  Back to Login
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  onClick={submitPasswords}
                >
                  Reset Password
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      email: "",
      linkSent: false,
      error: null,
    };
  }
  componentDidMount() {
    this.props.fetchTranslations([
      "resetPasswordText",
      "enterYourEmail",
      "backToLogin",
      "submit",
    ]);
  }

  validateForm = (ev) => {
    return ev.currentTarget.reportValidity();
  };

  submitHandler = async ( ev ) => {
    // stop submitting form
    ev.preventDefault();

    // validate form
    if( !this.validateForm(ev) ) return false;

    const { email } = this.state;
    this.setState({ error: null });
    if (email === "") {
      this.setState({ error: "Email required" });
      return;
    }
    const result = await AuthService.resetPassword(email);

    if (result) {
      this.setState({ linkSent: true, email: "" });
      return false;
    }


    this.props.onShowMessage( AuthService.error, "error" );

    
  }

  render() {
    const { email, linkSent, error } = this.state;

    const { translationError, translationsLoaded, _t } = this.props;
    const { hash } = this.props.match.params;
    console.debug("translations:", this.props.translations);

    if (!translationError && !translationsLoaded) return <Spinner />;
    // TODO: if translationError - return translation error
    // if hash is passed via url - show resetPassword 2 screen
    return this.props.match.params && hash ? (
      <ResetPassword2 hash={hash} />
    ) : (
      <Container className="flex column">
        <AutoBreadcrumbs items={breadcrumbs} />
        <div id="reset-password-first">
          <form className="reset-form" onSubmit={this.submitHandler}>
            <Typography variant="h1" color="primary">
              Reset password
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="body2" color="primary">
                  {linkSent
                    ? _t("resetPasswordText")
                    : "Please enter your email"}
                </Typography>
              </Grid>
              {linkSent ? null : (
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="email"
                    type="email"
                    name="email"
                    value={email}
                    error={error}
                    label="Email"
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                  />
                  {/* <Input
                    label="Email"
                    id="input-with-icon-adornment"
                    autoComplete="username"
                    type="email"
                    name="email"
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                    value={email}
                    error={error}
                    startAdornment={
                      <InputAdornment position="start">
                        <Email color="primary" />
                      </InputAdornment>
                    }
                  /> */}
                </Grid>
              )}
            </Grid>

            <div className="buttons flex column align-items-center">
                {linkSent ? null : (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"   
                    disabled={!Boolean(email)}                 
                  >
                    {_t("submit")}
                  </Button>                  
                )}

                <Button
                  variant="contained"
                  color="gray"                  
                  component={Link}
                  to={"/"}
                ><ChevronLeft />
                  {_t("backToLogin")}
                </Button>
              </div>
          </form>
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch( showMessage(message, type)),
  };
};

export default WithTranslations( connect (null, mapDispatchToProps)( ResetPassword ));
