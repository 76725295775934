import React from 'react';
import Parser from '../../../../../shared-components/Parser';
import Spinner from '../../../../../shared-components/Parser/Spinner';
import CourseService from '../../../../../services/course';
import ProgressService from '../../../../../services/progress';
import { connect } from 'react-redux';
import { showMessage } from '../../../../../redux/notificationActions';
import { showModal } from '../../../../../redux/modalActions';
import { Button } from '@material-ui/core';
import { Delete, SkipNext } from '@material-ui/icons';

class ExperimentCycle extends React.Component {
  state = {
    status: null,
    filtered: [],
    currentHtml: null,
    currentExperiment: null,
  };

  componentDidMount() {
    console.log('ExperimentCycle - this.props: ', this.props);
    this.populateState();
  }

  componentDidUpdate(prevProps) {
    const { chapter: old_chapter, current_experiment_id: old_id } = prevProps;
    const { chapter, current_experiment_id: id } = this.props;
    if (old_chapter !== chapter || old_id !== id) {
      this.populateState();
    }
  }

  updatePageStatus = async (status) => {
    const { currentExperiment } = this.state;
    const { course_id, chapter } = this.props;
    try {
      return await CourseService.updatePageStatus(
        course_id,
        currentExperiment.course_experiment_id,
        chapter,
        status
      );
    } catch (err) {
      console.debug('Error inside updatePageStatus in experiment cycle', err);
      return false;
    }
  };

  onQuestionChange = async (answersObj) => {
    const { currentExperiment } = this.state;
    const { course_id, chapter, pageIsDone } = this.props;

    // if page is done, don't do anything and show warning message
    if (pageIsDone) {
      this.props.onShowMessage(
        'This page is finished, your changes will not be saved',
        'warning'
      );
      return false;
    }

    try {
      const result = await ProgressService.updateAnswers(
        currentExperiment.course_experiment_id,
        chapter,
        answersObj
      );
      const {
        courseStatusChange,
        experimentStatusChanged,
        pageStatusChanged,
        message,
      } = result;

      // check if there is an error coming back from server
      if (message) {
        //this.props.onShowMessage(message, errorLevel);
        this.props.onShowModal('Attention!', message, [
          { type: 'ok', text: 'close' },
        ]);
      }

      this.props.flagsHandler(
        courseStatusChange,
        experimentStatusChanged,
        pageStatusChanged
      );

      //return answers to parser
      return result.answers;
    } catch (err) {
      console.debug('Error inside onQuestionChange inside ExperimentCycle');
    }
  };

  /**
   * Select current page and populate state with its data
   */
  async populateState() {
    // show spinner before anything
    this.setState({ currentExperiment: null });

    const { subDivisions, experiments, chapter, exp_id } = this.props;

    const current_experiment_id = exp_id || experiments[0].course_experiment_id;

    const currSub =
      subDivisions.find((s) => chapter === s.id) || subDivisions[0];

    const currentHtmlIndexes = currSub.index; // an array of indexes whose html has to be glued together.

    // selected experiment data
    const currentExperiment =
      experiments.find(
        (e) =>
          parseInt(e.course_experiment_id) === parseInt(current_experiment_id)
      ) || {};

    // find needed html within selected experiment using indexes
    const currentHtml = currentHtmlIndexes.reduce(
      (res, ind) => `${res}${currentExperiment[ind]}`,
      ''
    );

    // const experimentProgress = await ProgressService.getExperimentProgress(
    //   exp_id
    // );

    const pageAnswers = await ProgressService.getPageAnswers(
      current_experiment_id,
      chapter
    );

    // filtered questions - only the ones that are actually present on this page
    let filtered = [];
    if (pageAnswers) {
      for (let i = 0; i < currentExperiment.questions.length; i++) {
        if (
          Object.keys(pageAnswers).includes(
            currentExperiment.questions[i].question_id.toString()
          )
        ) {
          filtered = [...filtered, currentExperiment.questions[i]];
        }
      }
    }

    this.setState({
      currentHtml,
      currentExperiment,
      filtered,
      pageAnswers,
    });
  }

  async autofinishPage() {
    console.debug('autofinishing page');

    const { chapter, exp_id } = this.props;
    const flags = await ProgressService.autofinishPage(exp_id, chapter);
    if (!flags) {
      this.props.onShowMessage(ProgressService.error, 'error');
      return;
    }

    // extract flags
    const { courseStatusChange, experimentStatusChanged, pageStatusChanged } =
      flags;

    // call flags handler callback
    this.props.flagsHandler(
      courseStatusChange,
      experimentStatusChanged,
      pageStatusChanged
    );

    // repopulate and rerender
    await this.populateState();
  }

  async resetPageProgress() {
    console.debug('reetting page progress');

    const { chapter, exp_id } = this.props;
    const flags = await ProgressService.resetPageProgress(exp_id, chapter);
    if (!flags) {
      this.props.onShowMessage(ProgressService.error, 'error');
      return;
    }
    // extract flags
    const { courseStatusChange, experimentStatusChanged, pageStatusChanged } =
      flags;

    // call flags handler callback
    this.props.flagsHandler(
      courseStatusChange,
      experimentStatusChanged,
      pageStatusChanged
    );

    // repopulate and rerender
    await this.populateState();
  }

  render() {
    const { currentHtml, currentExperiment, filtered, pageAnswers } =
      this.state;

    const { user, current_page, chapter, pageIsDone } = this.props;

    // spinner is shown every time populateState is called
    if (!currentExperiment) return <Spinner />;

    const userIsTester =
      user &&
      Array.isArray(user.credentials) &&
      user.credentials.includes('tester');
    const pageHasQuestions = Boolean(pageAnswers);
    const pageIsCurrent = chapter && current_page === chapter;

    return (
      <>
        {
          // tester tools - show only if:
          userIsTester &&
            pageHasQuestions &&
            pageIsCurrent &&
            // page is not done yet
            !pageIsDone && (
              <div className='tester-tools'>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  startIcon={<SkipNext />}
                  onClick={this.autofinishPage.bind(this)}
                >
                  Auto-finish page
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  size='small'
                  startIcon={<Delete />}
                  onClick={this.resetPageProgress.bind(this)}
                >
                  reset page
                </Button>
              </div>
            )
        }

        <Parser
          html={currentHtml}
          onQuestionChange={this.onQuestionChange.bind(this)}
          questions={pageAnswers}
          allQuestions={filtered}
        />
      </>
    );
  }
}

const stateToProps = (state) => {
  const { user } = state.root;

  return { user };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
    onShowModal: (header, text, buttons) =>
      dispatch(showModal(header, text, buttons)),
  };
};

export default connect(stateToProps, mapDispatchToProps)(ExperimentCycle);
