import { Container, Typography, Button } from '@material-ui/core';
import {Link} from 'react-router-dom';
import React from 'react';
import WithTranslations from '../WithTranslations';
import { connect } from 'react-redux';
import { Home } from '@material-ui/icons';
import AutoBreadcrumbs from '../../shared-components/template/AutoBreadcrumbs';


let breadcrumbs = [
  { to: "/", icon: <Home /> },
  { text: "About us" },
];

class AboutUs extends React.Component {
  render() {

    const {_t, translationsLoaded, translationError, user } = this.props

    return <Container className="about">
      <AutoBreadcrumbs items={breadcrumbs} />
    <Typography color="primary" variant="h1"> {_t("About Us")}</Typography>
        <div className="skeleton text"></div>
        
        {
            // show Login button only if user is not set
        !user && 
        <div className="buttons"><Button component={Link} to="/" variant="contained" color="primary">{_t("Login")}</Button></div>
        }
    </Container> 
  }
}

const stateToProps = state => ({
  user: state.root.user
})

export default WithTranslations( connect( stateToProps )( AboutUs  ) )
