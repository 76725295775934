import React from 'react';
import ProgramService from '../../services/program';
import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Typography,
  Breadcrumbs,
} from '@material-ui/core';
import Spinner from '../template/spinner';
import { Link } from 'react-router-dom';
import { buildLink } from '../../utils/functions';
import WithTranslations from '../WithTranslations';

const defaultItemProps = {
  xs: 12,
  md: 4,
  lg: 3,
};

// TODO:
// export this List as stand-alone component and use it in
// components/course-list and components/programs

const ProgramList = (props) => {
  let { items, spacing = 4, itemProps, organization } = props;

  itemProps = { ...defaultItemProps, ...itemProps };

  return (
    <Grid container spacing={spacing} className='card-list'>
      {items.map((item, ind) => (
        <Grid item {...itemProps} key={`program-${ind}`}>
          <Card
            style={{
              backgroundColor: 'rgb(217, 217, 217)',
            }}
          >
            <CardActionArea
              program={item}
              component={Link}
              // to={`/program/${item.program_id}`}
              to={buildLink({ organization, program: item.program_id })}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  component='img'
                  src={
                    item.image
                      ? item.image
                      : 'https://images.ses-education.com/courses/course-no-image.png'
                  }
                  style={{ height: 'auto', width: 200 }}
                />

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CardContent component='h3'>{item.title}</CardContent>
                  <CardContent>{item.description}</CardContent>
                  {/* <CardContent>{item.description}</CardContent> */}
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

class Programs extends React.Component {
  state = {
    programs: null,
  };

  componentDidMount() {
    // fetch interface translations
    this.props.fetchTranslations( 
      [
        "My Programs"
      ] );
    this.fetchPrograms();
  }

  async fetchPrograms() {
    const programs = await ProgramService.getPrograms();
    this.setState({ programs });
  }

  render() {
    // console.log('Programs: props', this.props);

    // check whether translations are loaded and get _t function
    const { _t, noTranslationsLoaded } = this.props;
    if( noTranslationsLoaded() ) return <Spinner />

    const { organization } = this.props.match ? this.props.match.params : {};
    const { programs} = this.state;


    return (
      <Container>
        {/* <Breadcrumbs className='breadcrumbs'>
          <Typography color='inherit'>{`${organization}`}</Typography>
        </Breadcrumbs> */}
        <Typography variant='h1' color='primary' gutterBottom>
          { _t("My Programs") }
        </Typography>
        {programs === null ? (
          <Spinner />
        ) : programs === false ? (
          <Typography>
            Error fetching courses from server. Please try reloading page.
          </Typography>
        ) : (
          <ProgramList
            items={programs}
            itemProps={{ md: 12, lg: 12 }}
            {...{ organization }}
          />
        )}
      </Container>
    );
  }
}

export default WithTranslations( Programs );
