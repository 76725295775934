import React, { useState } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import { Switch } from 'react-router';
import RouteComponent from './RouteComponent';
import './style.scss';
import Notification from '../template/Notification';
import routes from '../../data/routes';
import Navbar from '../template/Navbar';
import { connect } from 'react-redux';
import { Menu as MenuIcon, Person as UserIcon } from '@material-ui/icons';
import { Container, Menu, MenuItem } from '@material-ui/core';
import { showMessage } from '../../redux/notificationActions';
import AuthService from '../../services/AuthService';
import defaultUniversityLogo from './images/default-university-logo.png';

const {
  REACT_APP_MEDIA_URL = 'https://images.ses-education.com/courses/organizations',
} = process.env;

const makeImageUrl = (image) => `${REACT_APP_MEDIA_URL}/${image}`;

class AuthorizedMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      menuTarget: null,
    };
  }

  render() {
    const { menuTarget } = this.state;
    const { location, user, onShowMessage, homeLink = '/' } = this.props;

    const { name = '', last_name = '', organizations } = user;

    const [university] = organizations || [];

    const RightComponent = () => {
      const show = (ev) => {
        setMenuTarget(ev.currentTarget);
      };

      const hide = (ev) => {
        setMenuTarget(null);
      };

      const logout = async (ev) => {
        setMenuTarget(null);
        const result = await AuthService.logout();

        if (!result) {
          onShowMessage(`Failed to logout`, 'error');
        }
      };

      const [menuTarget, setMenuTarget] = useState(null);

      return (
        <div className='top-right'>
          <div className='user-icon link' onClick={show}>
            <UserIcon />
            {name}
          </div>
          <Menu
            id='simple-menu'
            anchorEl={menuTarget}
            keepMounted
            open={Boolean(menuTarget)}
            onClose={hide}
          >
            <MenuItem component={Link} to={'/profile'} onClick={hide}>
              Profile
            </MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </div>
      );
    };

    const hasLogoImage =
      university.organization_logo_images &&
      university.organization_logo_images.logo_small;

    const LeftComponent = (
      <div className='top-left'>
        <Link to={homeLink}>
          <img
            src={
              hasLogoImage
                ? makeImageUrl(university.organization_logo_images.logo_small)
                : defaultUniversityLogo
            }
            alt={university.organization_title}
          />
          {!hasLogoImage && <span>{university.organization_title}</span>}
        </Link>
      </div>
    );

    const navbarProps = {
      location,
      LeftComponent,
      RightComponent: <RightComponent />,
      ContainerComponent: Container,
    };

    return (
      <BrowserRouter>
        <Navbar {...navbarProps} />

        <div className={`main`}>
          <Switch>
            {routes.map((route, ind) => (
              <RouteComponent {...{ ...route, key: `route-${ind}` }} />
            ))}
          </Switch>
          <Notification />
        </div>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default connect(null, mapDispatchToProps)(AuthorizedMain);
