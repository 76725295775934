import React from 'react';
import Spinner from '../../../../template/spinner';
import CourseService from '../../../../../services/course';
import ProgressService from '../../../../../services/progress';
import { Link, Redirect } from 'react-router-dom';
import ExpAccordion from './ExpAccordion';
import '..//../courses.scss';
import {
  Typography,
  Container,
  Grid,
  List,
  ListItem,
  Button,
} from '@material-ui/core';
import ExperimentCycle from './ExperimentCycle';
import pageMap from './../../../../../data/pages.json';
import './course-main.scss';
import { ChevronRightOutlined } from '@material-ui/icons';
import { buildLink, debounce } from '../../../../../utils/functions';

class CourseMain extends React.Component {
  /**
   * Debounced function to check element visibility
   */
  debouncedCheckVisible = debounce((el, callback) => {
    if (this.checkVisible(el)) {
      console.debug('debouncedCheckVisible: element is visible:\n', el);
      callback();
    }
  }, 100);

  constructor(props) {
    super(props);
    this.state = {
      experiments: null,
      pages: {},
      courseProgress: null,
      current_experiment_index: null,
      current_experiment_id: null,
      current_page: null,
      showButton: false,
      linkToNext: false,
      currentPageIndex: 0,
      routePageIndex: 0,
      pageIsDone: false,
      lastExperiment: false,
      error: null,
      next_experiment_index: null,
      btnVisible: false,
      subDivisions: [
        {
          title: 'Objectives',
          id: 'objectives',
          index: ['html_objectives', 'html_equipment'],
        },
        { title: 'Discussion', id: 'discussion', index: ['html_discussion'] },
        { title: 'Preparation Questions', id: 'prep', index: ['html_prep'] },
        { title: 'Experiment', id: 'experiment', index: ['html_experiment'] },
        {
          title: 'Summary Questions',
          id: 'summary',
          index: ['html_questions'],
        },
      ],
    };
    this.pageScrolledToBottom = false;
    this._proceedButtonRef = null;

    this.setBtnRef = (el) => {
      this._proceedButtonRef = el;
    };
  }

  async componentDidMount() {
    this._proceedButtonWasShown = false;

    await this.initCourseData();

    // this.proceedIfPageHasNoQuestions(); // open proceed btn in  objectives and discussions right away
    // this.handleScroll();

    // this.pageScrolledToBottom = false;
    // window.addEventListener('scroll', this.handleScroll.bind(this));
    window.addEventListener(
      'scroll',
      this.handleProceedButtonVisibility.bind(this)
    );
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.handleScroll.bind(this));
    window.removeEventListener(
      'scroll',
      this.handleProceedButtonVisibility.bind(this)
    );
  }

  async componentDidUpdate(prevProps, prevState) {
    //console.log('CourseMain componentDidUpdate - prevProps: ', prevProps);
    //console.log('CourseMain componentDidUpdate - prevState: ', prevState);
    const courseHasChanged = this.props.course !== prevProps.course;
    const experimentHasChanged =
      this.props.exp_id && this.props.exp_id !== prevProps.exp_id;
    const chapterHasChanged = this.props.chapter !== prevProps.chapter;

    // calling one of them in order : course/experiment/page .
    // ech one is called only if previous ones weren't called
    if (courseHasChanged) {
      await this.initCourseData();
    } else if (experimentHasChanged) {
      this.initExperimentData(this.props.exp_id);
      // await this.fetchExperimentProgress(this.props.exp_id);
    } else if (chapterHasChanged) {
      this.initPage();
    }
  }

  /**
   * Prepares and stores to state course-related data, then triggers experiment and page inits.
   * @returns
   */
  async initCourseData() {
    console.debug('initCourseData started');
    const { course_id } = this.props.course;
    const { exp_id } = this.props;
    if (!course_id) return;

    // TODO: separate experiments fetch from progress fetch!
    // TODO: progress fetch happens often and experiments are large.
    const experiments = await this.fetchExperiments(course_id);

    const courseProgress = await ProgressService.getCourseProgress(course_id);

    if (courseProgress) {
      // store course-related values to state
      this.storeCourseToState(exp_id, courseProgress, experiments);
    }
  }

  /**
   * Prepares and stores state values for a newly selected experiment, then triggers initExperimentData
   * @param {*} exp_id - experiment ID from URL
   * @param {*} current_experiment_id - current experiment ID from course progress
   * @param {*} experiments - array of experiments from course
   */
  storeCourseToState(exp_id, courseProgress, experiments) {
    let current_experiment_index;

    let { current_experiment_id } = courseProgress;

    // cast to integer if not empty
    current_experiment_id = current_experiment_id
      ? parseInt(current_experiment_id)
      : null;

    // calculate current experiment index, if possible
    if (experiments && current_experiment_id) {
      current_experiment_index =
        experiments.findIndex(
          (e) => e.course_experiment_id === current_experiment_id
        ) ||
        // in case ot wasn't found - make it null
        null;
    }

    // set corse-related state variables
    this.setState(
      {
        current_experiment_id,
        current_experiment_index,
        experiments,
        courseProgress,
      },
      async () => {
        // only proceed with other details if exp_id is set
        if (exp_id) {
          // init experiment data
          await this.initExperimentData(exp_id);
        }
      }
    );
  }

  /**
   * Fetches and stores in state experiment data then triggers page data update
   * @param {*} exp_id - experiment ID from URL
   */
  async initExperimentData(exp_id) {
    await this.fetchExperimentProgress(exp_id);
    await this.initPage();
  }

  async initPage() {
    // this.pageScrolledToBottom = false;

    await this.preparePageIndexes();

    // deal with button visibility
    this._proceedButtonWasShown = false;
    this.handleProceedButtonVisibility();

    // await this.proceedIfPageHasNoQuestions();
  }

  /**
   * Gets the current page and chapter and stores their subDivisions indexes in state.
   * Prepares link to next page and stores the pageIsDone boolean flag.
   */
  preparePageIndexes() {
    const { current_page, pages } = this.state;
    const { exp_id } = this.props;

    if (!exp_id || !current_page) return; // don't run this before exp_id and current page are fetched

    let { chapter } = this.props;

    if (!chapter) chapter = current_page; // if no chapter, go to current page

    const routePageIndex = pageMap.findIndex((p) => p === chapter);
    const currentPageIndex = pageMap.findIndex((p) => p === current_page);

    const linkToNext = this.prepareLinkToNext(routePageIndex, currentPageIndex);
    const pageIsDone = pages ? pages[chapter] === 'done' : false;

    this.setState({ routePageIndex, currentPageIndex, linkToNext, pageIsDone });
  }

  calc_next_exp_index() {
    const { experiments } = this.state;
    const { exp_id } = this.props;

    // it is -1 if no experiments loaded or no experiment selected
    if (!experiments || !exp_id) return -1;

    let index = null;
    experiments.map((exp, ind) => {
      if (parseInt(exp.course_experiment_id) === parseInt(exp_id)) {
        index = ind;
      }
    });
    return index + 1;
  }

  calc_curr_exp_ind(experiments, current_experiment_id) {
    current_experiment_id = parseInt(current_experiment_id);
    experiments.map((exp, ind) => {
      if (exp.course_experiment_id === current_experiment_id) {
        this.setState({ current_experiment_index: ind });
      }
    });
  }

  async fetchExperiments(id) {
    // don't need subscription here...
    // const experiments = await CourseService.getCourseExperiments(id, () =>
    //   this.fetchExperiments(id)
    // );
    return await CourseService.getCourseExperiments(id);
  }

  /**
   * Check whether element is within viewport
   * @param {*} elm DOM element
   * @returns {boolean}
   */
  checkVisible(elm) {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    console.debug(
      'checkVisible rect:',
      rect,
      '\nelm:',
      elm,
      '\nviewHeight:',
      viewHeight
    );
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  /**
   * Checks whether the Proceed button is within viewport,
   * and if it is and the page has no questions and is not yet done -
   * sends request to server to open next page
   */
  handleProceedButtonVisibility() {
    if (!this._proceedButtonWasShown) {
      if (this._proceedButtonRef)
        // cal debounced function with callback that runs proceedIfPageHasNoQuestions() in case of button visible
        this.debouncedCheckVisible(this._proceedButtonRef, () => {
          // set 'button shown' flag to true
          this._proceedButtonWasShown = true;
          console.debug(
            'handleProceedButtonVisibility: calling proceedIfPageHasNoQuestions()'
          );
          // this is the only place this function is called
          this.proceedIfPageHasNoQuestions();
        });
    }
  }

  // handleScroll() {
  //   const windowHeight =
  //     'innerHeight' in window
  //       ? window.innerHeight
  //       : document.documentElement.offsetHeight;
  //   const body = document.body;
  //   const html = document.documentElement;
  //   const docHeight = Math.max(
  //     body.scrollHeight,
  //     body.offsetHeight,
  //     html.clientHeight,
  //     html.scrollHeight,
  //     html.offsetHeight
  //   );
  //   const windowBottom = windowHeight + window.pageYOffset;

  //   if (windowBottom + 5 >= docHeight) {
  //     if (!this.pageScrolledToBottom) {
  //       this.proceedIfPageHasNoQuestions();
  //       this.pageScrolledToBottom = true;
  //     }
  //   }
  // }

  async flagsHandler(
    courseStatusChange,
    experimentStatusChanged,
    pageStatusChanged
  ) {
    const { current_experiment_id } = this.state;

    if (experimentStatusChanged || courseStatusChange) {
      await this.initCourseData();
    } else if (pageStatusChanged && current_experiment_id) {
      await this.fetchExperimentProgress(current_experiment_id);
    }
  }

  /**
   * Fetches current page and pages progress data from server and stores them to state.
   * After storing to state, triggers preparePageIndexes()
   * @param {*} exp_id
   */
  async fetchExperimentProgress(exp_id) {
    const result = await ProgressService.getExperimentProgress(exp_id);
    let { pages, current_page } = result;
    //console.log('PAGES: ', pages);
    //console.log('current_page: ', current_page);
    this.setState({ current_page, pages }, () => this.preparePageIndexes());
  }

  /**
   * if current page has no questions on it, update it as done and enable next button.
   */
  async proceedIfPageHasNoQuestions() {
    console.debug('proceedIfPageHasNoQuestions called');
    const { current_experiment_id, current_page, pageIsDone } = this.state;

    console.debug(
      'proceedIfPageHasNoQuestions current_experiment_id, current_page, pageIsDone:\n',
      current_experiment_id,
      current_page,
      pageIsDone
    );

    const pagesWithoutQuestions = ['discussion', 'objectives'];
    //console.log('state: ', this.state);
    if (
      current_experiment_id &&
      current_page &&
      pagesWithoutQuestions.includes(current_page) &&
      !pageIsDone
    ) {
      console.debug('proceedIfPageHasNoQuestions: sending proceed request');
      const result = await ProgressService.updateAnswers(
        current_experiment_id,
        current_page,
        {}
      );

      if (result) {
        const {
          courseStatusChange,
          experimentStatusChanged,
          pageStatusChanged,
        } = result;

        this.flagsHandler(
          courseStatusChange,
          experimentStatusChanged,
          pageStatusChanged
        );
      }
    }
  }

  /**
   * Prepares link for the "Next" button at the bottom of the page.
   * @param {*} routePageIndex
   * @param {*} currentPageIndex
   * @returns
   */
  prepareLinkToNext = (routePageIndex, currentPageIndex) => {
    const { current_experiment_id } = this.state;
    const { organization, prog_id, exp_id } = this.props;
    const { course_id } = this.props.course;

    // do not prepare link if route page is after current page - it won't be rendered anyway
    if (currentPageIndex < routePageIndex && exp_id === current_experiment_id) {
      return false;
    }

    const nextPage = pageMap[routePageIndex + 1]
      ? pageMap[routePageIndex + 1]
      : false;

    // get next experiment id
    const nextExpId = this.getNextExperimentId(exp_id);

    // if nextExpId is FALSE, this is the last experiment in course.
    this.setState({ lastExperiment: nextExpId === false });

    // get first chapter from pageMap (it's objectives, but maybe that'll change)
    const [firstPage] = pageMap;

    // const nextExperimentLink = `/program/${prog_id}/course/${course_id}/experiment/${nextExpId}/${firstPage}`;
    const nextExperimentLink = buildLink({
      organization,
      program: prog_id,
      course: course_id,
      slug: 'experiment',
      experiment: nextExpId,
      chapter: firstPage,
    });

    // const nextPageLink = `/program/${prog_id}/course/${course_id}/experiment/${current_experiment_id}/${nextPage}`;
    const nextPageLink = buildLink({
      organization,
      program: prog_id,
      course: course_id,
      slug: 'experiment',
      experiment: current_experiment_id,
      chapter: nextPage,
    });

    // if next page exists - it's the next page link, otherwise - next experiment link
    return nextPage ? nextPageLink : nextExperimentLink;
  };

  /**
   * Returns next experiment's id.
   * If given experiment is the last one in course, returns FALSE
   * If no experiments are loaded yet, returns NULL
   * @param {*} exp_id
   * @returns
   */
  getNextExperimentId(exp_id) {
    exp_id = parseInt(exp_id); // to match format

    const { experiments } = this.state;
    // if no experiments - return null
    if (!experiments) return null;

    // get experiment index
    const expIndex = experiments.findIndex(
      (e) => e.course_experiment_id === exp_id
    );

    // if it's the last one - return false
    if (expIndex === experiments.length - 1) return false;

    // oherwise, return next item's id
    return experiments[expIndex + 1].course_experiment_id;
  }

  markup = (html) => {
    return { __html: html };
  };

  render() {
    console.debug(this.props);

    const { organization, chapter, course, exp_id, prog_id } = this.props;
    const {
      experiments,
      courseProgress,
      current_experiment_id,
      subDivisions,
      current_experiment_index,
      current_page,
      currentPageIndex,
      routePageIndex,
      linkToNext,
      lastExperiment,
      pageIsDone,
    } = this.state;

    // wait for course progress to load
    if (
      !organization ||
      !course.course_id ||
      !current_experiment_id ||
      !experiments
    ) {
      return <Spinner />;
    }

    // course progress loaded, but no experiment selected - go to current experiment
    if (!exp_id) {
      // const jumpTo1 = `/program/${prog_id}/course/${course.course_id}/experiment/${current_experiment_id}`;
      const jumpTo1 = buildLink({
        organization,
        program: prog_id,
        course: course.course_id,
        slug: 'experiment',
        experiment: current_experiment_id,
      });

      return <Redirect to={jumpTo1} />;
    }

    //  experiment selected, but experiment progress is still loading
    if (!current_page) return <Spinner />;

    // if no chapter selected, or
    // chapter is larger than current page, or
    // selected chapter does not exist (<0) -
    // jump to current chapter (current_page)
    if (!chapter || routePageIndex > currentPageIndex || routePageIndex < 0) {
      // const jumpTo2 = `/program/${prog_id}/course/${course.course_id}/experiment/${current_experiment_id}/${current_page}`;
      const jumpTo2 = buildLink({
        organization,
        program: prog_id,
        course: course.course_id,
        slug: 'experiment',
        experiment: current_experiment_id,
        chapter: current_page,
      });
      return <Redirect to={jumpTo2} />;
    }

    const experimentsList = (
      <List>
        {experiments.map((exp, ind) => {
          return (
            <ListItem
              component={Link}
              // to={`/program/${prog_id}/course/${course.course_id}/experiment/${exp.course_experiment_id}`}
              to={buildLink({
                organization,
                program: prog_id,
                course: course.course_id,
                slug: 'experiment',
                experiment: exp.course_experiment_id,
              })}
              button={true}
              divider={true}
              disabled={ind > current_experiment_index}
              onClick={() => {
                this.setState({
                  current_experiment_id: exp.course_experiment_id,
                });
              }}
              selected={exp.course_experiment_id === exp_id}
              key={exp.course_experiment_id}
            >
              <ExpAccordion
                organization={organization}
                exp={exp}
                subDiv={subDivisions}
                course_id={course.course_id}
                currentPage={current_page}
                chapter={chapter}
                exp_id={parseInt(exp_id)}
                prog_id={parseInt(prog_id)}
              />
            </ListItem>
          );
        })}
      </List>
    );

    return (
      <Container className='course-main-container'>
        {this.state.error ? (
          <Typography className='error-message'>{this.state.error}</Typography>
        ) : (
          <Grid container spacing={3}>
            {/* ----------------------experiment tabs submenu------------------------------ */}
            <Grid item xs={12} md={3}>
              <div className='exp-list-container'>{experimentsList}</div>
            </Grid>
            {/* ----------------------experiment content------------------------------------ */}
            <Grid item xs={12} md={9}>
              <div className='exp-content-container'>
                {experiments && exp_id && chapter ? (
                  <>
                    <ExperimentCycle
                      {...{
                        organization,
                        prog_id,
                        course_id: course.course_id,
                        chapter,
                        // exp_id: current_experiment_id,
                        exp_id,
                        // pass pageIsDone to cycle in order to block controls on finished pages
                        pageIsDone,
                        // pass current page for usage in tester tools
                        current_page,
                        flagsHandler: this.flagsHandler.bind(this),
                        subDivisions,
                        experiments,
                        fetchExperiments: () =>
                          this.fetchExperiments(course.course_id),
                      }}
                    />

                    <div className='bottom-buttons'>
                      {
                        // last page in experiment
                        chapter === 'summary' &&
                        pageIsDone &&
                        !lastExperiment ? (
                          <>
                            {linkToNext && (
                              <Button
                                component={Link}
                                to={linkToNext}
                                variant='contained'
                                color='primary'
                                disabled={!pageIsDone}
                              >
                                <ChevronRightOutlined />{' '}
                                {'Go to next experiment'}
                              </Button>
                            )}
                            <Button
                              component={Link}
                              // to={`/program/${prog_id}/course/${course.course_id}/progress`}
                              to={buildLink({
                                organization,
                                program: prog_id,
                                course: course.course_id,
                                slug: 'progress',
                              })}
                              variant='contained'
                              color='secondary'
                              disabled={!pageIsDone}
                            >
                              {'View Experiment Results'}
                            </Button>
                          </>
                        ) : lastExperiment && chapter === 'summary' ? (
                          <>
                            {courseProgress &&
                              courseProgress.status === 'done' && (
                                <div className='vertical'>
                                  <Typography
                                    color='secondary'
                                    variant='h4'
                                    align='center'
                                  >
                                    Congradulations!
                                  </Typography>
                                  <Typography variant='body1' align='center'>
                                    You have finished this course.
                                  </Typography>
                                  <Button
                                    component={Link}
                                    // to={`/program/${prog_id}/course/${course.course_id}/progress`}
                                    to={buildLink({
                                      organization,
                                      program: prog_id,
                                      course: course.course_id,
                                      slug: 'progress',
                                    })}
                                    variant='contained'
                                    color='secondary'
                                    disabled={!pageIsDone}
                                  >
                                    {'See your results'}
                                  </Button>
                                </div>
                              )}
                          </>
                        ) : (
                          <Button
                            component={Link}
                            to={linkToNext}
                            variant='contained'
                            color='primary'
                            disabled={!pageIsDone}
                            ref={this.setBtnRef}
                          >
                            Next <ChevronRightOutlined />
                          </Button>
                        )
                      }
                    </div>
                  </>
                ) : null}
              </div>
            </Grid>
          </Grid>
        )}
      </Container>
    );
  }
}

export default CourseMain;
