import Base from './baseClientService';
import A from '../redux/actionTypes';

class AuthService extends Base {
  static async login(login, password) {
    try {
      const result = await this.POST('/auth/student/login', {
        login,
        password,
      });
      if (result) {
        const { user, token } = result;

        // dispatch redux action
        this.dispatch(this.actionTypes.LOGIN, { user, token });
        return result;
      } else {
        console.error('Wrong result received: ', result);
        return false;
      }
    } catch (e) {
      console.debug('Error: ', e.message);
      this.Error(e.message);
      return false;
    }
  }

  static async logout() {
    try {
      await this.POST('/auth/student/logout');
      this.dispatch(this.actionTypes.LOGOUT);
      return true;
    } catch (e) {
      console.error('Error logging out: ', e.message);
      return false;
    }
  }

  static async changePassword() {
    try {
      // TODO: get endpoint for password change on client
      //const result = await this.POST('/auth/student/???');
      return false;
    } catch (e) {
      console.error('Error changing password: ', e.message);
      return false;
    }
  }

  static async renew() {
    try {
      const result = await this.POST('/auth/renew');
      console.log('AuthService - renew - result: ', result);
      if (result && result.token) {
        const { token } = result;

        this.dispatch(A.RENEW_TOKEN, token);
        return true;
      } else {
        console.error('Wrong data received: ', result);
      }
    } catch (e) {
      console.error('Error renewing token: ', e.message);
      return false;
    }
  }

  static async resetPassword(email) {
    try {
      const result = await this.POST('/auth/student/reset-password', {
        email,
      });
      return result;
    } catch (e) {
      console.error('Error logging out: ', e.message);
      return this.Error(e.message);
    }
  }

  static async checkHash(hash) {
    try {
      const result = await this.POST(
        `/auth/student/reset-password/check/${hash}`
      );
      console.log('checkHash - result: ', result);
      return result;
    } catch (e) {
      console.error('Reset password hash invalid: ', e.message);
      return this.Error(e.message);
    }
  }

  static async updatePasswordAfterReset(password, hash) {
    try {
      const result = await this.POST(
        '/auth/student/reset-password/update',
        {
          password,
          hash,
        }
      );
      console.log('updatePasswordAfterReset - result: ', result);
      return result;
    } catch (e) {
      console.error('Error updating password after reset: ', e.message);
      return this.Error(e.message);
    }
  }
}

Base.extend(AuthService);

export default AuthService;
