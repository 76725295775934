import React, { useEffect } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Typography,
  // Breadcrumbs,
} from '@material-ui/core';
import Spinner from '../template/spinner';
import ProgramService from '../../services/program';
import CourseService from '../../services/course';
import { Link, Redirect } from 'react-router-dom';
import Error from '../template/Error';
import './courses-list.scss';
import { buildLink } from '../../utils/functions';
import { Home } from '@material-ui/icons';
import AutoBreadcrumbs from '../../shared-components/template/AutoBreadcrumbs';
import WithTranslations from '../WithTranslations';

const defaultItemProps = {
  xs: 12,
  md: 4,
  lg: 3,
};

const ExperimentList = ({ experiments }) => (
  <ul className='course-experiments-list'>
    {Array.isArray(experiments) &&
      experiments.map((e, ind) => <li key={`exp-name-${ind}`}>{e.title}</li>)}
  </ul>
);

// TODO:
// export this List as stand-alone component and use it in
// components/course-list and components/programs
const CourseList = (props) => {
  let { items, spacing = 4, itemProps, program_id, organization } = props;
  itemProps = { ...defaultItemProps, ...itemProps };

  return (
    <Grid container spacing={spacing} className='courses-list card-list'>
      {items.map((item, ind) => (
        <Grid item {...itemProps} key={`course-${ind}`}>
          <Card
            style={{
              backgroundColor: 'rgb(217, 217, 217)',
            }}
          >
            <CardActionArea
              component={Link}
              to={buildLink({
                organization,
                program: program_id,
                course: item.course_id,
              })}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  component='img'
                  src={
                    item.image
                      ? item.image
                      : 'https://images.ses-education.com/courses/course-no-image.png'
                  }
                  style={{ height: 'auto', width: 200 }}
                />

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <CardContent component='h3'>
                    {item.course_code} - {item.title}
                  </CardContent>
                  <CardContent style={{ padding: 0 }}>
                    {/* {generateList(item.course_id)} */}
                    <ExperimentList experiments={item.experiments} />
                  </CardContent>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <CardContent component='h3'>Progress</CardContent>
                  <CardContent>
                    {parseInt(item.progress_percent || 0)}%
                  </CardContent>
                  {item.progress_percent === 100 && (
                    <>
                      <CardContent component='h3'>Your score is</CardContent>
                      <CardContent>
                        {parseInt(item.total_score || 0)} out of 100
                      </CardContent>
                    </>
                  )}
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

class Courses extends React.Component {
  state = {
    currentCourses: null,
    programs: null,
    error: null,
    experimentNames: {},
  };

  async componentDidMount() {
    console.log('this.props: ', this.props);
    this.fetchPrograms();
  }

  extractCourses = async () => {
    const { programs } = this.state;
    if (!Array.isArray(programs)) {
      this.setState({
        error:
          'There was an error fetching programs data. Please, try reloading the page.',
      });
      return;
    }

    //const allCourses = await CourseService.getUserCourses();

    // get the id of the current program
    const program_id = this.props.match.params.prog_id;
    // get current program from all user programs
    const currentProgram = programs.filter(
      (p) => p.program_id === parseInt(program_id)
    );

    // get list of all courses attached to current program
    let currentCourses = currentProgram ? currentProgram[0].courses : [];

    currentCourses = await this.extractExperiments(currentCourses);
    console.debug('extractCourses currentCourses:', currentCourses);
    // this.setState({ currentCourses }, () =>
    //   this.extractExperiments(currentCourses)
    // );
    this.setState({ currentCourses });
  };

  async fetchPrograms() {
    const programs = await ProgramService.getPrograms();
    this.setState({ programs }, () => this.extractCourses());
  }

  async extractExperiments(currentCourses) {
    if (!Array.isArray(currentCourses)) return currentCourses;

    // create array of ids of all courses present in currentProgram
    // let currentCoursesIds = [];
    // currentCourses.forEach((course) => {
    //   currentCoursesIds.push(course.course_id);
    // });
    // let currentCoursesIds = currentCourses.map( course => course.course_id );

    // extract the names of experiments in every course
    let experimentNames = { ...this.state.experimentNames };

    // for (const id of currentCoursesIds) {
    //   // const result = await CourseService.getCourseExperiments(id);
    //   const result = await CourseService.getCourseExperiments(id);
    //   experimentNames[id] = result.map((exp) => exp.title);
    // }
    for (let i = 0; i < currentCourses.length; i++) {
      // store within currentCourses
      currentCourses[i].experiments =
        await CourseService.getCourseExperimentsList(
          currentCourses[i].course_id
        );
    }

    // save names to state
    this.setState({ experimentNames });
    //console.log('extractExperiments - currentCoursesIds: ', currentCoursesIds);
    console.log('extractExperiments - experimentNames: ', experimentNames);

    return currentCourses;
  }

  render() {
    // link to program list (pass it from parent component if needed)
    const { rootLink = '/' } = this.props;

    const { currentCourses, programs, error, experimentNames } = this.state;
    // const program_id = this.props.match.params.prog_id;
    const { organization, prog_id: program_id } = this.props.match
      ? this.props.match.params
      : {};

    // wait for programs list to load
    // if ( programs === false || !program_id || !organization ) return error ? <Error {...{error}} /> : <Spinner />;
    if (!programs) return error ? <Error {...{ error }} /> : <Spinner />;

    const programIndex = programs
      ? programs.findIndex(
          (p) => parseInt(p.program_id) === parseInt(program_id)
        )
      : false;

    if (programIndex < 0) return <Redirect to={rootLink} />;

    const programTitle = programs[programIndex].title;

    // Build breadcrumbs
    let breadcrumbs = [
      { to: buildLink({ organization }), icon: <Home /> },
      {
        to: buildLink({ organization, program: program_id }),
        text: programTitle,
      },
    ];

    return (
      <Container>
        <AutoBreadcrumbs items={breadcrumbs} />

        {/* <Breadcrumbs className='breadcrumbs'>
          <Link to={`/${organization}`}>{`${organization}`}</Link>
          <Typography color='inherit'>{`${program_id}`}</Typography>
        </Breadcrumbs> */}
        <Typography variant='h1' color='primary' gutterBottom>
          {`Courses of program ${programTitle}`}
        </Typography>
        {currentCourses === null ||
        programs === null ||
        experimentNames === {} ? (
          <Spinner />
        ) : currentCourses === false || programs === false ? (
          <Typography>
            Error fetching data from server. Please try reloading page.
          </Typography>
        ) : (
          <CourseList
            program_id={this.props.match.params.prog_id}
            items={currentCourses}
            // names={experimentNames}
            itemProps={{ md: 12, lg: 12 }}
            {...{ organization }}
          />
        )}
      </Container>
    );
  }
}

export default WithTranslations(Courses);
