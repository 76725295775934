import React from 'react';
import './App.scss';
import Main from './components/Main';
import Notification from './components/template/Notification';
import { ThemeProvider } from '@material-ui/core';
import theme1 from './data/theme1';

function App() {
  return (
    <ThemeProvider theme={theme1}>
      <Main />
      <Notification />
    </ThemeProvider>
  );
}

export default App;
