import React from "react";
import pipeline from "../../Utils/pipeline";

import {
  Container,
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import "./QuizComponent.scss";

import { Cancel, Check } from "@material-ui/icons";

const WrongAnswer = (props) => <Radio component={Cancel} color="secondary" />
const RightAnswer = (props) => <Radio component={Check} color="primary" />
const SelectedAnswer = (props) => <Radio {...props} />

class Question extends React.Component {
  /**
   * gets an answer returns text only, image only or both
   * */
  prepareQuestionContent = (ans) => {
    return (
      <div>
        {ans.aimage ? (
          ans.aimage.value !== "" ? (
            <div style={{ flex: 1 }}>
              <img
                src={ans.aimage.value}
                alt={ans.aimage.meta.title}
                className="image"
              />
            </div>
          ) : null
        ) : null}
        {ans.text ? (
          <div
            className={`question-text`}
            dangerouslySetInnerHTML={{ __html: pipeline(ans.text) }}
          ></div>
        ) : null}
      </div>
    );
  };

  render() {
    const { content, answer, serverSideAnswer, solution, errorObject } = this.props;

    console.debug("Question serverSideAnswer:", serverSideAnswer)
    console.debug("Question errorObject:", errorObject)
    console.debug("Question answers:", content.answers)

    const questionIsAnsweredCorrectly = serverSideAnswer && !errorObject;

    return (
      <Container maxWidth="sm">
        <div className="question-container">
          <FormControl component="fieldset">
            {/* --------------question title------------ */}
            <FormLabel id="question-title">
              <div
                dangerouslySetInnerHTML={{
                  __html: pipeline(content.title),
                }}
              ></div>
            </FormLabel>
            {/*  ------------question image------------- */}
            {content.qimage ? (
              <img
                src={content.qimage.value}
                alt={content.qimage.meta.title}
                className="question-image"
              />
            ) : null}
            {/* --------------possible answers------------ */}
            <RadioGroup 
            className={questionIsAnsweredCorrectly ? "answered-correctly" : ""}
            >
              {content.answers.map((ans) => {

                const isRightAnswer = serverSideAnswer === ans.id && answer === ans.id;
                const isSelected = answer === ans.id || serverSideAnswer === ans.id ;
                const isWrongAnswer = errorObject && serverSideAnswer === ans.id;
                return (
                  <FormControlLabel
                    key={`quiz-answ-${ans.id}`}
                    className={ isSelected ? "selected" : ""}
                    value={ans.id}
                    // errorObject && answer === ans.id  means the question answer is wrong and is equal to current answer's id
                    control={
                      isWrongAnswer ? (
                        <WrongAnswer />
                      ) :
                        // right answer
                        isRightAnswer ? <RightAnswer /> :
                          // any other answer
                          <SelectedAnswer checked={isSelected} />

                      // if the answer if selected, show the button as selected
                      // <Radio 
                      // checkedIcon={serverSideAnswer === ans.id ? <Check /> : undefined }
                      // checked={answer === ans.id} color="primary"
                      // />

                    }
                    label={this.prepareQuestionContent(
                      ans,
                      questionIsAnsweredCorrectly,
                      isRightAnswer,
                      isWrongAnswer,
                      isSelected
                    )}
                    onClick={() => {
                      // only change value if question has not been answered correctly
                      if (!questionIsAnsweredCorrectly) {
                        //pass to QuizComponent the answer to the curr question
                        solution(content.id, ans.id)
                      }
                    }
                    }
                  />
                )
              }
              )}
            </RadioGroup>
          </FormControl>
        </div>
      </Container>
    );
  }
}

export default Question;
