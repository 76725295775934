import React, { useEffect, useState } from 'react';
import pageMap from './../../../../../data/pages.json';
import {
  List,
  ListItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { buildLink } from '../../../../../utils/functions';

//takes experiment as input and outputs accordion with list
const ExpAccordion = (props) => {
  //console.log('ExpAccordion - props: ', props);
  const {
    organization,
    exp,
    exp_id,
    prog_id,
    course_id,
    currentPage,
    chapter,
    subDiv,
  } = props;
  const [selected, setSelected] = useState(
    pageMap.findIndex((p) => p === currentPage)
  );

  useEffect(() => {
    setSelected(pageMap.findIndex((p) => p === chapter));
  }, [chapter, currentPage]);

  const pagesHandler = (page) => {
    const { currentPage } = props;
    const currentIndex = pageMap.findIndex((p) => p === currentPage);
    const index = pageMap.findIndex((p) => p === page);

    return index > currentIndex;
  };

  return (
    <Accordion
      expanded={exp_id === exp.course_experiment_id}
      className='accordion-list'
    >
      <AccordionSummary>
        <Typography className='accordion-experiment-title'>
          {exp.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {subDiv.map((item) => {
            // const chapterLink = `/program/${prog_id}/course/${course_id}/experiment/${exp.course_experiment_id}/${item.id}`;
            const chapterLink = buildLink({
              organization,
              program: prog_id,
              course: course_id,
              slug: 'experiment',
              experiment: exp.course_experiment_id,
              chapter: item.id,
            });

            return (
              <ListItem
                component={NavLink}
                to={chapterLink}
                key={subDiv.indexOf(item)}
                button={true}
                divider={true}
                disabled={pagesHandler(item.id)}
                onClick={() => {
                  setSelected(subDiv.indexOf(item));
                }}
                selected={selected === subDiv.indexOf(item)}
              >
                {item.title}
              </ListItem>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpAccordion;
