import Base from './baseClientService';

class OrganizationsService extends Base {
 
  static async getUserOrganizations(){        
    try{
      const result = await this.GET("/student/organizations");
      if( !Array.isArray(result) ) return this.Error("Wrong data type received. Please try reloading the page.")
      return result;
    }catch(e){
      return this.Error(e.message || "Unknown error. Please, try reloading page.")
    }
  }
}
Base.extend(OrganizationsService);

export default OrganizationsService;
