import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import { Switch } from 'react-router';
import RouteComponent from './RouteComponent';
import './style.scss';
import routes from '../../data/non-authorized-routes';
import Navbar from '../template/Navbar';
import { connect } from 'react-redux';
import WithTranslations from '../WithTranslations';
import CenterLogo from '../template/Navbar/CenterLogo';
// import { Person } from '@material-ui/icons';

class NonAuthorizedMain extends React.Component {
  componentDidMount() {
    this.props.fetchTranslations(['Login']);
  }
  render() {
    const { location, _t } = this.props;

    const navbarProps = {
      location,
      LeftComponent: <div className='top-left'></div>,
      RightComponent: <div className='top-right'></div>,
      // MiddleComponent: <CenterLogo showLink={false} />
    };

    return (
      <BrowserRouter>
        <Navbar {...navbarProps} />
        <div className={`main`}>
          <Switch>
            {routes.map((route, ind) => (
              <RouteComponent {...{ ...route, key: `route-${ind}` }} />
            ))}
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}
const stateToProps = (state) => {
  const { user } = state.root;
  return { user };
};

export default connect(stateToProps, null)(WithTranslations(NonAuthorizedMain));
