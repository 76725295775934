import {
  Container,
  Typography,
  Tabs,
  Tab,
  // Breadcrumbs,
} from '@material-ui/core';

import AutoBreadcrumbs from '../../../shared-components/template/AutoBreadcrumbs';
import React from 'react';
import Spinner from '../../template/spinner';
import Service from '../../../services/course';
import { Link } from 'react-router-dom';

import './courses.scss';

import { courseSubroutes as routes } from '../../../data/routes';
import experiment_pages from '../../../data/experiment_pages.json';
import { buildLink } from '../../../utils/functions';
import { Home } from '@material-ui/icons';
import Error from '../../template/Error';

class Course extends React.Component {
  state = {
    course: null,
    fetchError: null,
    tabsValue: 0,
    ChildComponent: null,
  };
  componentDidMount() {
    const { id, slug = 'preface' } = this.props.match.params;
    console.log('Course - this.props: ', this.props);
    this.fetchData(id, slug);
    this.setSubroute(slug);
  }

  componentDidUpdate(prevProps) {
    const { id, slug = '' } = this.props.match.params;
    const { id: old_id, slug: old_slug = '' } = prevProps.match.params;
    if (id !== old_id) this.fetchData(id, slug);
    if (slug !== old_slug) this.setSubroute(this.props.match.params.slug);
  }

  // subrouting
  setSubroute = (slug) => {
    const ind = routes.findIndex((i) => i.link === slug);

    if (routes[ind]) {
      const ChildComponent = routes[ind].component;

      if (ind !== this.state.tabsValue) {
        // set tab value if not set yet
        this.setState({ ChildComponent, tabsValue: ind });
      } else {
        this.setState({ ChildComponent });
      }
    } else {
      const ChildComponent = routes[0].component;
      this.setState({ ChildComponent, tabsValue: 0 });
    }
  };

  async fetchData(id, slug) {
    if (id) {
      const course = await Service.getCourse(id);
      const fetchError = Service.error;
      this.setState({ course, fetchError });
    } else {
      console.debug('Not found id:', id);
      this.setState({ course: false, fetchError: "Course ID wasn't found" });
    }
  }

  setTab = (tabsValue) => this.setState({ tabsValue });

  render() {
    const { course, tabsValue, ChildComponent, fetchError } = this.state;
    const {
      id,
      slug = 'preface',
      exp_id,
      chapter,
      prog_id,
      organization,
      
    } = this.props.match.params;


    // don't proceede if no course is set
    if( course === null ) return <Spinner />



    // Build breadcrumbs
    // TODO: get organization/program names 

    // get course slug data
    const slugData = routes.find( s => s.link === slug ) || {title: "Error", link: slug }

    let breadcrumbs = [
      {to: buildLink({organization}), icon : <Home/> },
      {to: buildLink({organization, program: prog_id }), text: "Program" },
      // {to: buildLink({organization, program: prog_id, course: id }), text: course.course_code + ": " + course.title },
      {to: buildLink({organization, program: prog_id, course: id }), text: course ? course.course_code : "Unknown course" },
      {to: buildLink({organization, program: prog_id, course: id, slug }), text: slugData.title }
    ]

    // if it's experiment and chapter is set, add chapter title
    if( slug === "experiment" && chapter){
      const chapterData = experiment_pages[chapter] || {title: "Error"};
      breadcrumbs = [...breadcrumbs,  
        {to: buildLink({organization, program: prog_id, course: id, slug, chapter }), text: chapterData.title }
      ]
    } 

    console.debug("course:", course)
    return course === false ? (
              
        <Error 
          title="Error fetching course data"
          error={fetchError}
          breadcrumbsItems={breadcrumbs}
        />        
      
    ) : (
      <Container key={`course-component-${id}`}>
        <AutoBreadcrumbs items={breadcrumbs} />
        <Typography variant='h1' color='primary'>
          {course.course_code} {course.title}
        </Typography>
        <Typography variant='body1'>{course.description}</Typography>
        <Tabs value={tabsValue}>
          {routes.map((t, ind) => (
            <Tab
              key={`tab-${ind}`}
              component={Link}
              // to={`/program/${prog_id}/course/${id}${
              //   t.link ? `/${t.link}` : ''
              // }`}
              to={buildLink({
                organization,
                program: prog_id,
                course: id,
                slug: t.link,
              })}
              label={t.title}
              index={ind}
            />
          ))}
        </Tabs>
        {ChildComponent ? (
          <ChildComponent
            key={`course-route-${slug}`}
            organization={organization}
            prog_id={prog_id}
            course={course}
            exp_id={exp_id}
            chapter={chapter}
          />
        ) : ChildComponent === null ? (
          <Spinner />
        ) : (
          <Typography>Error 404: page not found </Typography>
        )}
      </Container>
    );
  }
}

export default Course;
