import React from 'react'
import TranslationService from '../../services/translation'

/**
 * HOC to encapsulate translations fetching from service and inject them into props.
 * @usage Wrap component with WithTranslations() before exporting:
 * class Something extends React.Component {... }
 * export default WithTranslations( Something )
 * 
 * Then use props.fetchTranslations( ["Yes", "No"]) to fetch the needed translations 
 * ```js
 * and props.translations object to receive them in render() :
 * render(){
 *      const {translations, noTranslationsLoaded} = this.props
 *      // use noTranslationsLoaded() to check whether the translations were loaded
 *      if( noTranslationsLoaded() ) return <div>Loading...</div>
 *      // translations loaded, use them in render
 *      return <>
    *             <Button>{translations['Yes']}</Button> {// fetch directly from translations object }
    *             <Button>{_t('No')}</Button>  {// use the handy _t() function }
 *             </>
 * } ```
 * 
 */
const WithTranslations = Wrapped => {


    return class extends React.Component{
    
        state = {
            translations: {},
            translationError: null
        }

        fetchTranslations = async ( ids ) => {
            const result = await TranslationService.get( ids );

            if( !result ) {
                // error happened
                this.setState({translationError: TranslationService.error } )
                return;
            }

            const {translations} = this.state;

            // insert/overwrite result to existing translations
            // reset error to null
            this.setState( { translations: {...translations, ...result }, translationError: null } );
        }

        noTranslationsLoaded = () => Object.keys(this.state.translations).length === 0;

        /**
         * Fetch translation from translations object or return key as is, if not found
         * @param {*} index 
         * @returns 
         */
        translate = index => this.state.translations[index] || index;
    
        render(){
            const {translations, translationError } = this.state;
            // inject translations object, translation error, fetchTranslations and noTranslationsLoaded functions into child props
            return <Wrapped {...{
                ...this.props, 
                translations,
                _t: this.translate,
                translationError, 
                fetchTranslations: this.fetchTranslations,
                noTranslationsLoaded: this.noTranslationsLoaded
            } } />
        }
    }
}


export default WithTranslations