import React from 'react';
import { connect } from 'react-redux';
import { deleteMessage } from '../../../redux/notificationActions';
import { Snackbar, Badge } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

class Notification extends React.Component {
  closeHandler = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.onDeleteMessage();
  };

  render() {
    const { messages } = this.props;

    const arrLen = messages.length;

    // deal with potential error when non-string values fail to output
    const messageText = arrLen ? messages[arrLen - 1].message + '' : null;

    const numberToShow = arrLen > 1 ? arrLen : 0; // show nothing until it's at least 2

    return (
      <>
        {messageText && (
          <div>
            <Snackbar open={true} onClose={this.closeHandler}>
              <Badge badgeContent={numberToShow} color='primary'>
                <Alert
                  elevation={6}
                  variant='filled'
                  severity={messages[arrLen - 1].type}
                  onClose={this.closeHandler}
                >
                  {messageText}
                </Alert>
              </Badge>
            </Snackbar>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.ui.messages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteMessage: () => dispatch(deleteMessage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
