import { Container } from '@material-ui/core'
import React from 'react'


class Support extends React.Component{

    render(){
        return <Container>Support</Container>
    }
}

export default Support