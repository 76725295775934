import React from 'react';
import { connect } from 'react-redux';
import { HorizontalBar } from 'react-chartjs-2';
import {
  Container,
  Grid,
  Typography,
  Button,
  Tooltip,
} from '@material-ui/core';
import Spinner from '../../../../../shared-components/Parser/Spinner';
import {
  Delete as DeleteIcon,
  SkipPrevious,
  ChevronRight,
  ChevronLeft,
} from '@material-ui/icons';
import '..//../courses.scss';
import ProgressService from '../../../../../services/progress';
import { showModal } from '../../../../../redux/modalActions';
import { Link } from 'react-router-dom';
import './progress.scss';
import { buildLink } from '../../../../../utils/functions';

const options = {
  maintainAspectRatio: false,
  title: {
    display: true,
    text: 'Course Progress',
    fontSize: 25,
  },
  legend: {
    position: 'top',
  },
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          max: 100,
        },
      },
    ],
  },
};

const defaultState = {
  stats: null,
  course_progress: null,
  loading: false,
  data: {
    labels: [],
    datasets: [
      {
        label: 'Experiment Progress',
        data: [],
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: 'Summary Result',
        data: [],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  }, //chart data can't be null
  options: { ...options },
  error: null,
};

class Progress extends React.Component {
  state = {
    ...defaultState,
    // stats: null,
    // course_progress: null,
    // loading: false,
    // data: {}, //chart data can't be null
    // options: {},
    // error: null,
  };

  async componentDidMount() {
    console.log('componentDidMount - this.props: ', this.props);
    const { course_id } = this.props.course;

    this.setState({ loading: true });
    await this.fetchProgress(course_id);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.course !== prevProps.course) {
      await this.fetchProgress(this.props.course.course_id);
    }
  }

  async fetchProgress(id) {
    const stats = await ProgressService.getCourseStats(id);

    if (!stats) {
      this.setState({ error: 'Fetching progress failed' });
      return false;
    }

    const course_progress = await ProgressService.getCourseProgress(id);

    this.setState({ stats, course_progress }, () => {
      this.createChartData(stats);
    });
  }

  createChartData(stats) {
    const data = {
      labels: stats ? stats.map((exp) => exp.title) : [],
      datasets: [
        {
          label: 'Experiment Progress',
          data: stats ? stats.map((exp) => exp.overall_progress) : [],
          backgroundColor: 'rgba(255, 99, 132, 0.6)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
        {
          label: 'Summary Result',
          data: stats ? stats.map((exp) => exp.summary_progress) : [],
          backgroundColor: 'rgba(54, 162, 235, 0.6)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
      ],
    };

    this.setState({ data, loading: false });
  }

  async resetProgress() {
    const id = this.props.course ? this.props.course.course_id : null;

    if (!id) {
      console.error('Course ID not found!');
      return;
    }

    this.setState({ loading: true });

    const result = await ProgressService.resetCourseProgress(id);

    if (result) {
      // fetch data again
      await this.fetchProgress(id);
    }

    this.setState({ loading: false });
  }

  async resetLastExperiment() {
    const id = this.props.course ? this.props.course.course_id : null;

    if (!id) {
      console.error('Course ID not found!');
      return;
    }

    this.setState({ loading: true });

    const result = await ProgressService.resetLastExperiment(id);

    if (result) {
      // fetch data again
      await this.fetchProgress(id);
    }

    this.setState({ loading: false });
  }

  render() {
    const { data, options, error, loading, stats, course_progress } =
      this.state;
    const { user, organization, prog_id: program, course } = this.props;
    const numOfExp = stats ? stats.length : 4;

    // TODO: calculate this and store to course progress at server!
    // cheat and calculate total score on frontend:
    // let total_score = 0;
    // let progress_percent = 0;
    // if (Array.isArray(stats)) {
    //   let score_tmp = 0;
    //   let progress_tmp = 0;
    //   for (let i = 0; i < stats.length; i++) {
    //     score_tmp += stats[i].summary_progress || 0;
    //     progress_tmp += stats[i].overall_progress || 0;
    //   }
    //   // total_score = Math.ceil(score_tmp / stats.length);
    //   // total_progress = Math.ceil(progress_tmp / stats.length);
    // }
    const { total_score, progress_percent } = course_progress || {};

    const courseIsFinished =
      course_progress && course_progress.status === 'done';

    console.debug('Program: ', program, 'props:', this.props);

    const linkToParentProgram = buildLink({ organization, program });

    // const courseCurrentPage = `/program/${prog_id}/course/${course.course_id}/experiment/${course.current_experiment_id}`;
    const courseCurrentPage = buildLink({
      organization,
      program,
      course: course.course_id,
      slug: 'experiment',
      experiment: course.current_experiment_id,
    });

    return (
      <Container id='progress'>
        {error ? (
          <Typography className='error-message'>{error}</Typography>
        ) : loading ? (
          <Spinner text='Loading progress...' />
        ) : (
          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingTop: '15px',
              paddingBottom: '15px',
            }}
          >
            <Grid item xs={12}>
              <HorizontalBar
                data={data}
                options={options}
                height={numOfExp * 100} // assign hight according to number of experiments
              />
            </Grid>
            <Grid item xs={12}>
              {course_progress && (
                <div className='course-score'>
                  {
                    // show congradulations when course is done
                    courseIsFinished && (
                      <>
                        <Typography variant='h6' color='secondary'>
                          You have finished the course!
                        </Typography>
                        <div className='score'>
                          Your total course score is <span>{total_score}</span>{' '}
                          out of 100{' '}
                        </div>
                      </>
                    )
                  }
                  {!courseIsFinished && (
                    <>
                      <div className='progress'>
                        <span>{progress_percent}%</span> of the course finished
                      </div>
                      <div className='score'>
                        Your total course score so far is{' '}
                        <span>{total_score}</span> out of 100{' '}
                      </div>
                    </>
                  )}
                </div>
              )}
            </Grid>
            <Grid item xs={12} className='progress-actions'>
              <Button
                variant='contained'
                color='primary'
                size='small'
                startIcon={<ChevronLeft />}
                component={Link}
                to={linkToParentProgram}
              >
                Back to program
              </Button>
              {
                // only show 'continue' button when course is not done
                !courseIsFinished && (
                  <Button
                    component={Link}
                    to={courseCurrentPage}
                    variant='contained'
                    color='primary'
                    size='small'
                    endIcon={<ChevronRight />}
                  >
                    Continue Course
                  </Button>
                )
              }
            </Grid>
            {user && user.credentials.includes('tester') ? (
              <Grid item xs={12} className='progress-actions'>
                <Tooltip
                  title={
                    <>
                      <span className='progress-tooltip-text'>
                        Resets last experiment progress to start or, if last
                        experiment progress is less than 3 pages deep, resets
                        previous experiment to start.
                      </span>
                    </>
                  }
                  placement='bottom'
                  arrow
                >
                  <Button
                    onClick={() => this.resetLastExperiment()}
                    variant='contained'
                    color='secondary'
                    size='small'
                    startIcon={<SkipPrevious />}
                  >
                    Reset last experiment
                  </Button>
                </Tooltip>
                <Button
                  onClick={() => this.resetProgress()}
                  variant='contained'
                  color='secondary'
                  size='small'
                  startIcon={<DeleteIcon />}
                >
                  Reset all progress
                </Button>
              </Grid>
            ) : null}
          </Grid>
        )}
      </Container>
    );
  }
}

const stateToProps = (state) => {
  const { user } = state.root;

  return { user };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowModal: (header, text, buttons) =>
      dispatch(showModal(header, text, buttons)),
  };
};

export default connect(stateToProps, mapDispatchToProps)(Progress);
