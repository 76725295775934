import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import root from './reducers';
import ui from './uiReducers';

const store = createStore(
  combineReducers({ root, ui }),
  compose(applyMiddleware(thunk))
);
export default store;
