import { createMuiTheme } from "@material-ui/core";


const theme1 = createMuiTheme({
    palette: {
        primary: {
            main: "#00479b"
        },
        secondary: {
            main: "#ff0048"
        }
    },
    typography: {
        fontFamily: [
            'Noto Sans JP', 'sans-serif'
        ].join(", ")
    }
})

export default theme1;