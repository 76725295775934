import Base from './baseClientService';


class TranslationService extends Base {
  static async get( ids = [] ) {
    
    try {

      // if only one key is passed as string - wrap it into an array
      if( !Array.isArray(ids) && typeof ids === "string" )
        ids = [ ids ];
  
      if( !Array.isArray(ids) ) return {[ids]: ids}; // whatever it is, just keep it to yourself!

      const items = await this.GET(`/student/translation`, {
        params: { ids }
      });
      //console.log('ProgramService - items: ', items);
      return items;


    } catch (e) {
      console.error('Error getting translations: ', ids, '\n Error message:', e.message);
      return this.Error("Error getting translations");
    }
  }
}
Base.extend(TranslationService);

export default TranslationService;
