import React from 'react'


class FormulaSheet extends React.Component{

    render(){
        return("FormulaSheet component")
    }
}

export default FormulaSheet