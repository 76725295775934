import Programs from '../components/programs';
import Courses from '../components/courses-list';
import Course from '../components/courses/item';
import Catalog from '../components/courses/catalog';
import AboutUs from '../components/AboutUs';
import Support from '../components/Support';
import P404 from '../components/template/Page404';
import Organizations from '../components/Organizations';
import Profile from '../components/Profile';

import {
  Preface,
  CourseMain,
  Progress,
  FormulaSheet,
  Guidance,
} from '../components/courses/item/pages';

const components = {
  Organizations,
  Programs,
  Courses,
  Course,
  Catalog,
  AboutUs,
  Profile,
  Support,
  P404,
};

// TODO:  .default at the end is needed due to the batch export via courses/item/pages/index.
// may need to refactor this
export const courseSubroutes = [
  { link: 'preface', title: 'Preface', component: Preface.default },
  { link: 'experiment', title: 'Course', component: CourseMain.default },
  { link: 'progress', title: 'Progress', component: Progress.default },
  {
    link: 'formula-sheet',
    title: 'Formula sheet',
    component: FormulaSheet.default,
  },
  { link: 'guidance', title: 'Guidance', component: Guidance.default },
];

// the list of available subroutes, joined for usage in route regex
const courseSubrouteList = courseSubroutes
  .filter((r) => r.link)
  .map((r) => r.link)
  .join('|');

export const routes = [
  {
    exact: true,
    path: '/',
    component: components['Organizations'],
    bodyClass: 'bg-dark',
  },
  {
    exact: true,
    path: '/about-us',
    component: components['AboutUs'],
    bodyClass: 'bg-dark',
  },
  {
    exact: true,
    path: '/profile',
    component: components['Profile'],
  },
  {
    exact: true,
    path: '/support',
    component: components['Support'],
    bodyClass: 'bg-dark',
  },
  {
    exact: true,
    path: '/:organization',
    component: components['Programs'],
    bodyClass: 'bg-dark',
  },
  {
    exact: true,
    path: '/:organization/program/:prog_id',
    component: components['Courses'],
    bodyClass: 'bg-dark',
  },

  {
    exact: true,
    path: `/:organization/program/:prog_id/course/:id/:slug(${courseSubrouteList})?/:exp_id?/:chapter?`,
    component: components['Course'],
  },

  { exact: false, path: '*', component: components['P404'] },
];

export default routes;
