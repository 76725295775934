import React from 'react';
import A from '../../redux/actionTypes';
import Actions from '../../redux/actions';
import { connect } from 'react-redux';

let usageDefer = Date.now();
const deferUsageDelay = 1000; // ms

/**
 * HOC to incapsulate authorization
 */
const AuthComponent = (
  Authorized,
  NonAuthorized = () => <>Non-authorized component</>
) => {
  const mapStateToProps = (state) => {
    return {
      user: state.root.user,
      tokenTimestamp: state.root.tokenTimestamp,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      setUsageTime: () => dispatch({ type: A.SET_USAGE_TIMESTAMP }),
      // sent : () => dispatch({type: A.SEND_CONTACT} ),
      // error : message => dispatch({type: A.SHOW_MESSAGE, payload:{message, type: "error"}}),
      // resetMessage: () => dispatch( {type: A.RESET_CONTACT} )
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class extends React.Component {
      componentDidMount() {
        document.getElementById('root').addEventListener('click', () => {
          clearTimeout(usageDefer);
          usageDefer = setTimeout(
            () => this.props.setUsageTime(),
            deferUsageDelay
          );
        });

        Actions.onTokenUpdate(this.props.tokenTimestamp);
      }
      render() {
        const { user } = this.props;
        console.log('USER : ', user);
        return user ? (
          <Authorized {...this.props} />
        ) : (
          <NonAuthorized {...this.props} />
        );
      }
    }
  );
};

export default AuthComponent;
