import ActionTypes from './actionTypes';

export const showMessage = (message, type) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SHOW_MESSAGE,
      payload: { message, type },
    });
  };
};

export const deleteMessage = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_MESSAGE,
      
    });
  };
};
